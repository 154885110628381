import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/logo.png';
import emailjs from 'emailjs-com';
import './header2.css';

const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const { t, i18n } = useTranslation();

    const SubmitHandler = (e) => {
        e.preventDefault();
        emailjs.send('service_wwh6av5', 'template_qefeo44', formData, 'sIgedgIeVFkZu589z')
            .then((result) => {
                alert('Message sent successfully!');
            }, (error) => {
                alert('An error occurred, please try again.');
            });
    };

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };

    return (
        <header className="main-header s2">
            <div className="header-upper">
                <div className="container-fluid clearfix">
                    <div className="header-inner">
                        <div className="logo">
                            <Link onClick={ClickHandler} to="/">
                                <img src={Logo} alt="Logo" />
                            </Link>
                        </div>
                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-lg">
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li><Link onClick={ClickHandler} to="/">{t('Home')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/about">{t('About')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/property">{t('Projects')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/services">{t('Services')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('Contact us')}</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        {/* Language switcher with globe icon */}
                        <div className="language-switcher">
                            <button className="language-button">
                                <i className="fa fa-globe"></i>
                            </button>
                            <div className="language-dropdown-content">
                                <button onClick={() => changeLanguage('en')}>English</button>
                                <button onClick={() => changeLanguage('ro')}>Română</button>
                            </div>
                        </div>

                        <div className="menu-icons">
                            <div className="menu-sidebar">
                                <button onClick={() => setMenuState(!menuActive)}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sidebar menu that slides in from the right */}
            <div
                className="mobileMenu"
                style={{
                    position: 'fixed',
                    right: menuActive ? '0' : '-300px',
                    top: 0,
                    zIndex: 9999,
                    height: '100vh',
                    width: '300px',
                    background: '#1e1e1e',
                    transition: 'right 0.3s ease',
                    boxShadow: '0px 10px 40px rgba(50, 50, 50, 0.1)',
                    overflowY: 'auto',
                    display: menuActive ? 'block' : 'none',
                    paddingTop: '60px' // Add padding at the top
                }}
            >
                <div className="menu-close">
                    <div
                        className="close"
                        onClick={() => setMenuState(!menuActive)}
                        style={{
                            width: '40px',
                            height: '40px',
                            lineHeight: '40px',
                            textAlign: 'center',
                            background: '#ca9c6a',
                            position: 'absolute',
                            top: '15px',
                            left: '15px',
                            color: '#fff',
                            cursor: 'pointer',
                            fontSize: '24px',
                            borderRadius: '50%',
                        }}
                    >
                        ✕
                    </div>
                </div>

                <ul className="responsivemenu" style={{ listStyle: 'none', paddingLeft: '0', paddingTop: '40px' }}>
                    <li>
                        <Link to="/" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                        {t('Home')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                        {t('About')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/property" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                        {t('Projects')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/services" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                        {t('Services')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                        {t('Contact')}
                        </Link>
                    </li>
                </ul>

                {/* Centered Social Icons */}
                <div className="social-icons" style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
                    <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px' }}>
                        <i className="fa-brands fa-tiktok"></i>
                    </a>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px' }}>
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/markapbs/" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px' }}>
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', margin: '0 10px' }}>
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header2;
