import React from 'react';
import Projects from '../../api/Projects';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProjectSectionS2 = (props) => {
    const { t } = useTranslation();

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="apartment-section s2 text-center">
            <div className="container">
                <div className="section-title mb-75">
                    <h2>
                        {t('projectSection.chooseProject')} <span className="thin">{t('projectSection.project')}</span>
                    </h2>
                    <p>{t('projectSection.description')}</p>
                </div>
                <div className="apartment-carousel">
                    <div className="row">
                        {Projects.slice(0, 6).map((project, i) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={i}>
                                <div className="apartment-item">
                                    <div className="apartment-img">
                                        <img src={project.pImg} alt={t('projectSection.altText')} />
                                    </div>
                                    <div className="apartment-content">
                                        <img src={project.ssIcon} alt={t('projectSection.iconAltText')} />
                                        <h3>
                                            <Link onClick={ClickHandler} to={`/project-single/${project.slug}`}>
                                                {project.pTitle}
                                            </Link>
                                        </h3>
                                        <span>{t('projectSection.size', { size: project.width })}</span>
                                        <p>{t(`projectSection.descriptions.${project.slug}`)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectSectionS2;
