import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation();
    const [forms, setForms] = useState({
        name: '',
        email: '',
        date: '',
        time: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: t('contactForm.validation.namemessage'),
            email: t('contactForm.validation.emailmessage'),
            message: t('contactForm.validation.messagemessage')
        }
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            emailjs.send('service_wwh6av5', 'template_qefeo44', forms, 'sIgedgIeVFkZu589z')
                .then((result) => {
                    alert(t('contactForm.success'));
                    setForms({
                        name: '',
                        email: '',
                        date: '',
                        time: '',
                        message: ''
                    });
                    validator.hideMessages();
                }, (error) => {
                    alert(t('contactForm.error'));
                });
        } else {
            validator.showMessages();
        }
    };

    return (
        <section className="contact-page mb-30">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-11">
                        <form className="contact-form bg-light rmt-0" onSubmit={(e) => submitHandler(e)}>
                            <div className="section-title text-center mb-40">
                                <h2>{t('contactForm.title')}</h2>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            value={forms.name}
                                            type="text"
                                            name="name"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={t('contactForm.fields.name')}
                                        />
                                        {validator.message('name', forms.name, t('contactForm.validation.name'))}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            value={forms.email}
                                            type="email"
                                            name="email"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            placeholder={t('contactForm.fields.email')}
                                        />
                                        {validator.message('email', forms.email, t('contactForm.validation.email'))}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            onBlur={(e) => changeHandler(e)}
                                            onChange={(e) => changeHandler(e)}
                                            value={forms.message}
                                            type="text"
                                            name="message"
                                            placeholder={t('contactForm.fields.message')}
                                        />
                                        {validator.message('message', forms.message, t('contactForm.validation.message'))}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="theme-btn mt-40">{t('contactForm.submit')}</button>
                                </div>
                            </div>
                            <div className="title-rotated">contact</div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
