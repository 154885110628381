import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "Home": "Home",
        "About": "About",
        "Projects": "Projects",
        "Services": "Services",
        "Contact us": "Contact us",
        "footer": {
          "contact": "Contact",
          "description": "Find out all the ways to enjoy furniture around the world.",
          "address": "A: 82C Str Căminului, Manolache, Bucharest",
          "phone": "T: 0721 212 212",
          "copyright": "by"
        },
        "hero": {
            "slide1": {
            "title": "Professional Shop Designs",
            "text": "We have no problems to implement your dreams"
            },
            "slide2": {
            "title": "Modern Shop Design",
            "text": "We can create anything you aspire to"
            },
            "slide3": {
            "title": "Modern Offices Design",
            "text": "If you are searching a way to make your business look nicer, call us!"
            },
            "button": "Discover More"
        },
        services: {
            "Design-&-Planning": {
              title: "Design & Planning",
              subtitle1: "Our design and planning services blend innovation and practicality to bring your vision to life. We work closely with clients to develop thoughtful, well-rounded plans that meet aesthetic, functional, and budgetary requirements.",
              subtitle2: "From initial concept development to detailed planning, we guide you through each stage, ensuring that your project aligns with your goals and adheres to industry standards. Our collaborative approach and attention to detail result in outstanding designs that set the foundation for successful project execution.",
              description: "Creating comprehensive design and planning strategies tailored to meet client expectations.",
              des2: "We ensure each phase of design aligns seamlessly with project goals.",
              des3: "Our team excels in strategy, design iteration, and efficient project execution.",
              capabilities: "With extensive experience in design and planning, we deliver customized solutions that blend creativity with functionality. Our capabilities include comprehensive project assessments, innovative design, and strategic planning.",
              "ListOfCapabilities": [
                    "Client Consultation and Vision Analysis",
                    "Conceptual Design and Development",
                    "Detailed Planning and Blueprint Creation",
                    "Design Iteration and Refinement",
                    "Project Oversight and Management"
                ],
              approach: "We prioritize client collaboration and continuous feedback throughout the design and planning process. By maintaining open communication and detailed attention, we ensure the final design is both innovative and practical.",
              "progress": [
                    "Initial Consultation and Vision Analysis",
                    "Concept Creation",
                    "Detailed Planning",
                    "Feedback and Refinement",
                    "Execution and Project Management"
                ],
            },
            "Custom-Solutions": {
              title: "Custom Solutions",
              subtitle1: "Our custom solutions are designed to meet the specific needs and challenges of each client. We conduct a thorough analysis to understand the project requirements and develop a tailored approach that ensures successful outcomes.",
              subtitle2: "From initial research and concept development to prototyping and implementation, our team prioritizes continuous feedback and iteration to refine the solution and maximize its effectiveness.",
              description: "Delivering customized strategies that cater to unique client challenges.",
              des2: "We excel in creating tailored solutions that enhance project success.",
              des3: "Our approach focuses on thorough analysis, prototyping, and continuous refinement.",
              capabilities: "Our expertise in custom solutions is centered on innovation and strategic problem-solving. By integrating advanced technology and creative thinking, we develop solutions that align with client goals and deliver maximum value.",
              "ListOfCapabilities": [
                    "Needs Assessment and Project Analysis",
                    "Custom Strategy Development",
                    "Prototyping and Testing",
                    "Feedback Integration and Iterative Improvements",
                    "Solution Implementation and Review"
                ],
              approach: "We begin by assessing the unique needs of each project, followed by strategy development and iterative prototyping. Our process is refined through continuous feedback to ensure optimal performance and results.",
              "progress": [
                    "Comprehensive Needs Analysis",
                    "Custom Strategy Creation",
                    "Prototyping and Testing",
                    "Feedback and Iterative Refinement",
                    "Final Implementation"
                ],
            },
            "Furniture-&-Decor": {
              title: "Furniture & Decor",
              subtitle1: "We believe that furniture and decor should not only beautify a space but also enhance its functionality. Our team provides expert advice and custom solutions for selecting and arranging furniture and decor that align with your style and practical needs.",
              subtitle2: "Our services include everything from design consultation and custom furniture creation to coordinating installations. We ensure that every piece complements the overall aesthetic of the space, creating an environment that is cohesive and inviting.",
              description: "Crafting functional and aesthetically pleasing furniture and decor solutions.",
              des2: "Our designs emphasize style, quality, and functionality.",
              des3: "From concept to completion, we ensure each piece adds value to the space.",
              capabilities: "We offer a curated selection of high-quality furniture and decor items, alongside custom design services to fit any client’s vision. Our team ensures that each piece enhances the space’s aesthetics and practicality.",
              "ListOfCapabilities": [
                    "Design Consultation and Planning",
                    "Custom Furniture Design",
                    "Selection of Quality Materials and Pieces",
                    "Coordination and Installation",
                    "Final Styling and Finishing Touches"
                ],
              approach: "Our approach to furniture and decor is holistic and client-focused. We begin with a detailed consultation to understand the client’s vision, followed by sourcing, custom design, and meticulous installation.",
              "progress": [
                    "Design Consultation",
                    "Custom Design and Selection",
                    "Installation Coordination",
                    "Final Styling"
                ],
            },
            "Exterior-Design": {
              title: "Exterior Design",
              subtitle1: "Our exterior design services transform outdoor spaces into beautiful, functional environments. We use a strategic approach that combines landscaping, hardscaping, and outdoor furniture selection to enhance both aesthetics and usability.",
              subtitle2: "From the initial site assessment to the final execution, we work closely with clients to create outdoor areas that align with their vision. Our designs prioritize sustainability, practicality, and visual appeal, ensuring an outdoor space that stands out.",
              description: "Transforming outdoor areas into functional, beautiful spaces.",
              des2: "We incorporate client vision and site potential for impactful exterior designs.",
              des3: "Our team handles everything from landscaping to lighting for a cohesive result.",
              capabilities: "Our exterior design capabilities include comprehensive landscaping, hardscaping, and outdoor lighting plans that enhance the space’s aesthetic and functionality.",
              "ListOfCapabilities": [
                    "Site Assessment and Feasibility Analysis",
                    "Comprehensive Landscaping and Hardscaping",
                    "Outdoor Furniture Selection",
                    "Lighting Design and Implementation",
                    "Coordination and Installation"
                ],
              approach: "We start with a thorough site assessment to understand the client’s vision and site constraints. Our team then creates detailed plans that include landscaping, hardscaping, and lighting to enhance both aesthetics and functionality.",
              "progress": [
                "Site Assessment",
                "Landscaping and Hardscaping Design",
                "Furniture and Lighting Selection",
                "Implementation and Installation",
                "Final Touches"
                ],
            },
            "Interior-Design": {
              title: "Interior Design",
              subtitle1: "Our interior design services focus on creating spaces that are both beautiful and functional. We work closely with clients to understand their style, preferences, and practical needs, turning their vision into reality.",
              subtitle2: "Our process includes space planning, selecting materials and finishes, furniture and decor placement, and project management. We ensure that each design aligns with client expectations and enhances their daily living experience.",
              description: "Crafting interior spaces that inspire and elevate everyday life.",
              des2: "We blend aesthetics and practicality to create exceptional interiors.",
              des3: "From planning to styling, we manage each detail for a seamless finish.",
              capabilities: "Our interior design capabilities range from space planning and material selection to comprehensive project coordination. We focus on creating cohesive, stylish, and functional interiors that reflect the client’s vision.",
              "ListOfCapabilities": [
                "Client Consultation and Space Analysis",
                "Space Planning and Layout Optimization",
                "Material and Finish Selection",
                "Furniture and Decor Placement",
                "Project Management and Coordination"
              ],
              approach: "We begin with a detailed consultation to capture the client’s vision and requirements. Our team then develops a tailored plan that incorporates aesthetics, functionality, and comfort, ensuring a seamless and stylish result.",
              "progress": [
                    "Initial Consultation",
                    "Space Planning and Layout",
                    "Material and Furniture Selection",
                    "Installation and Styling",
                    "Project Completion"
                ],
            },
            "Concept-Planning": {
              title: "Concept Planning",
              subtitle1: "Concept planning is essential to set the foundation for any successful project. Our team collaborates with clients to brainstorm, develop, and refine initial ideas, ensuring that they align with project goals and market trends.",
              subtitle2: "Once a concept is developed, we move forward with strategic planning and prototyping. Our iterative process incorporates feedback and testing, resulting in a well-defined concept ready for execution.",
              description: "Turning innovative ideas into actionable plans through strategic concept planning.",
              des2: "Our process ensures concepts are both innovative and feasible.",
              des3: "From brainstorming to finalization, we manage each step for successful results.",
              capabilities: "We are skilled in transforming initial ideas into comprehensive plans through strategic brainstorming, market analysis, and feasibility studies. Our focus is to create concepts that are impactful and ready for development.",
             "ListOfCapabilities": [
                    "Idea Generation and Brainstorming",
                    "Market Research and Analysis",
                    "Feasibility Studies",
                    "Concept Development and Prototyping",
                    "Feedback and Iterative Refinement"
                ],
              approach: "We approach concept planning with an emphasis on collaboration and strategic thinking. From initial brainstorming to final testing, we involve clients at every step to create well-rounded, actionable concepts.",
              "progress": [
                    "Initial Brainstorming",
                    "Market and Feasibility Analysis",
                    "Concept Development",
                    "Feedback and Refinement",
                    "Final Plan Creation"
                ],
            }
        },
        "capabilities_title": "Our Capabilities",
        "approach_title": "Our Approach",
        "work_process_title": "Our Work Process",
        "related_service_title": "Related Service",
        "contact_title": "Any question? Let's discuss",
        "contact_subtitle": "Get in touch with us to see how we can help you with your project",
        "about": {
        "years_experience": "Years of Experience",
        "best_architect": "BEST ARCHITECT",
        "small_team": "A small efficient",
        "interior_team": "interior",
        "designing_team": "designing team",
        "welcome_message": "welcome to hendon residence showcase"
        },
        "funFact": {
        "shops_made": {
          "count": "450",
          "subTitle": "Shops",
          "sts": "Made"
        },
        "happy_clients": {
          "count": "50",
          "subTitle": "Happy",
          "sts": "Clients"
        },
        "completed_projects": {
          "count": "147",
          "subTitle": "Completed",
          "sts": "Projects"
        },
        "team_members": {
          "count": "30",
          "subTitle": "Team",
          "sts": "Members"
        }
        },
        "faq": {
        "sectionTitle": {
          "subTitle": "Best Architecture",
          "title": "Architecture did expect",
          "highlight": "strategist"
        },
        "questions": [
          {
            "id": "1",
            "header": "Core Architecture",
            "body": "Our core architecture services focus on delivering innovative and efficient design solutions that integrate both form and function. We ensure each project meets the highest quality standards while reflecting the client's unique vision and needs."
          },
          {
            "id": "2",
            "header": "Offices",
            "body": "Our office design solutions create spaces that enhance productivity, comfort, and collaboration. We tailor each design to incorporate modern amenities, natural light, and ergonomic layouts, resulting in environments that inspire teams and support business goals."
          },
          {
            "id": "3",
            "header": "Shops",
            "body": "Designing shops, from large supermarkets like Lidl to home improvement stores like Leroy Merlin, requires a strategic approach to space planning and customer experience. Our designs focus on clear navigation, product accessibility, and visual appeal, ensuring an engaging and efficient shopping environment."
          },
          {
            "id": "4",
            "header": "Restaurants",
            "body": "Our restaurant design services blend ambiance, functionality, and customer experience. We create inviting spaces that optimize seating arrangements, ensure smooth traffic flow, and incorporate brand identity, resulting in a memorable dining experience."
          }
        ]
        },
        "contactForm": {
            "title": "Request a Visit",
            "fields": {
            "name": "Name",
            "email": "Email",
            "subject": "Choose a Service",
            "message": "Message"
            },
            "serviceOptions": {
            "designPlanning": "DESIGN & PLANNING",
            "customSolutions": "CUSTOM SOLUTIONS",
            "furnitureDecor": "FURNITURE & DECOR"
            },
            "validation": {
            "name": "required|alpha_space",
            "email": "required|email",
            "message": "required",
            "namemessage": "The name field is required.",
            "emailmessage": "The email field is required and must be a valid email address.",
            "messagemessage": "The message field is required."
            },
            "submit": "Submit Now",
            "success": "Message sent successfully!",
            "error": "An error occurred, please try again."
        },
        "ctaSection": {
        "title": "Call for Action",
        "subtitle": "Get Ideas",
        "fields": {
            "email": "Enter your email"
        },
        "submit": "Subscribe"
        },
        "projectSection": {
            "chooseProject": "Choose a",
            "project": "Project",
            "description": "We can help you markup your business",
            "altText": "Apartment",
            "iconAltText": "Icon",
            "size": "from {{size}} m2",
            "descriptions": {
                "Casa Vlăsia": "Premium Furniture",
                "HA RO": "Full Shop",
                "Leroy Merlin": "Chain of shops",
                "La Doi Pași": "Up to 25 yearly",
                "Any Shop": "Over 450 shops already made",
                "Apartaments and Houses": "Over 20 houses and apartments"
            }
        },
        "projectSingle": {
            "pageSub": "Project",
            "modernDevelopment": "A Modern Development",
            "keyFacilities": "Key Facilities:",
            "projectPark": "Project Park",
            "gallery": "Gallery",
            "retailShops": "Retail Shops",
            "facilities": {
            "wellnessSpa": "Wellness & Spa: A tranquil space dedicated to relaxation and rejuvenation.",
            "conferenceRooms": "Conference Rooms: Equipped with modern AV facilities for corporate events and meetings.",
            "restaurant": "Restaurant: Fine dining options with a range of international cuisines.",
            "fitnessCenter": "Fitness Center: High-end gym with personal trainers and modern equipment.",
            "library": "Library: Quiet space with a vast collection of books and digital resources.",
            "rooftopBars": "Rooftop Bars: Relaxing spaces offering panoramic views and cocktails."
            }
        },
        "plans": {
            "projectPlans": "Project",
            "plans": "Plans",
            "buildingPlan": "Building Plan",
            "render": "Render",
            "furnitureProject": "Furniture Project",
            "implementation": "Implementation",
            "imageNotAvailable": "Image not available"
        },
      }
    },
    ro: {
      translation: {
        "Home": "Acasă",
        "About": "Despre noi",
        "Projects": "Proiecte",
        "Services": "Servicii",
        "Contact us": "Contactează-ne",
        "footer": {
          "contact": "Contact",
          "description": "Descoperă toate modalitățile de a te bucura de mobilă în întreaga lume.",
          "address": "A: Str Căminului nr. 82C, Manolache, București",
          "phone": "T: 0721 212 212",
          "copyright": "de"
        },
        "hero": {
            "slide1": {
            "title": "Designuri Profesionale pentru Magazine",
            "text": "Nu avem nicio problemă în a-ți implementa visele"
            },
            "slide2": {
            "title": "Design Modern pentru Magazine",
            "text": "Putem crea orice îți dorești"
            },
            "slide3": {
            "title": "Design Modern pentru Birouri",
            "text": "Dacă cauți o modalitate de a-ți face afacerea mai atrăgătoare, contactează-ne!"
            },
            "button": "Descoperă Mai Mult"
        },
        services: 
        {
            "Design-&-Planning": {
              "title": "Proiectare și planificare",
              subtitle1: "Serviciile noastre de proiectare și planificare combină inovația și practicitatea pentru a aduce viziunea ta la viață. Lucrăm îndeaproape cu clienții pentru a dezvolta planuri bine gândite, care îndeplinesc cerințele estetice, funcționale și bugetare.",
              subtitle2: "De la dezvoltarea conceptului inițial până la planificarea detaliată, te ghidăm prin fiecare etapă, asigurându-ne că proiectul tău se aliniază cu obiectivele tale și respectă standardele industriei. Abordarea noastră colaborativă și atenția la detalii dau naștere unor proiecte remarcabile care stabilesc fundația pentru o execuție de succes.",
              description: "Crearea de strategii de proiectare și planificare cuprinzătoare, adaptate pentru a răspunde așteptărilor clientului.",
              des2: "Ne asigurăm că fiecare fază a proiectării se aliniază fără probleme cu obiectivele proiectului.",
              des3: "Echipa noastră excelează în strategie, iterarea designului și execuția eficientă a proiectului.",
              capabilities: "Cu o experiență vastă în design și planificare, oferim soluții personalizate care combină creativitatea cu funcționalitatea. Capabilitățile noastre includ evaluări de proiect cuprinzătoare, design inovator și planificare strategică.",
              "ListOfCapabilities": [
                    "Consultare cu clientul și analiză a viziunii",
                    "Design conceptual și dezvoltare",
                    "Planificare detaliată și creare de planuri",
                    "Iterația și rafinarea designului",
                    "Supraveghere și gestionare a proiectului"
                ],
              approach: "Prioritizăm colaborarea cu clienții și feedback-ul continuu pe tot parcursul procesului de proiectare și planificare. Prin menținerea unei comunicări deschise și o atenție detaliată, ne asigurăm că designul final este atât inovator, cât și practic.",
              "progress": [
                    "Consultare inițială și analiză a viziunii",
                    "Crearea conceptului",
                    "Planificare detaliată",
                    "Feedback și rafinare",
                    "Execuție și gestionarea proiectului"
                ],
            },
            "Custom-Solutions": {
              title: "Soluții personalizate",
              subtitle1: "Soluțiile noastre personalizate sunt concepute pentru a răspunde nevoilor și provocărilor specifice ale fiecărui client. Efectuăm o analiză amănunțită pentru a înțelege cerințele proiectului și dezvoltăm o abordare personalizată care asigură rezultate de succes.",
              subtitle2: "De la cercetarea inițială și dezvoltarea conceptului până la prototipare și implementare, echipa noastră prioritizează feedback-ul continuu și iterarea pentru a rafina soluția și a maximiza eficiența acesteia.",
              description: "Oferirea de strategii personalizate care răspund provocărilor unice ale clientului.",
              des2: "Excelăm în crearea de soluții personalizate care sporesc succesul proiectului.",
              des3: "Abordarea noastră se concentrează pe analiză riguroasă, prototipare și rafinament continuu.",
              capabilities: "Expertiza noastră în soluții personalizate este axată pe inovație și rezolvarea strategică a problemelor. Integrând tehnologia avansată și gândirea creativă, dezvoltăm soluții care se aliniază cu obiectivele clientului și oferă valoare maximă.",
              "ListOfCapabilities": [
                    "Evaluarea necesităților și analiza proiectului",
                    "Dezvoltarea unei strategii personalizate",
                    "Prototipare și testare",
                    "Integrarea feedback-ului și îmbunătățiri iterative",
                    "Implementarea și revizuirea soluției"
                ],
              approach: "Începem prin evaluarea nevoilor unice ale fiecărui proiect, urmată de dezvoltarea strategiei și prototiparea iterativă. Procesul nostru este rafinat prin feedback continuu pentru a asigura performanța optimă și rezultate.",
              "progress": [
                    "Analiză completă a nevoilor",
                    "Crearea strategiei personalizate",
                    "Prototipare și testare",
                    "Feedback și rafinare iterativă",
                    "Implementare finală"
                ],
            },
            "Furniture-&-Decor": {
              title: "Mobilier și decor",
              subtitle1: "Credem că mobilierul și decorul nu doar înfrumusețează un spațiu, ci îi sporesc și funcționalitatea. Echipa noastră oferă sfaturi de specialitate și soluții personalizate pentru selectarea și aranjarea mobilierului și a decorului care să se potrivească stilului și nevoilor tale practice.",
              subtitle2: "Serviciile noastre includ totul, de la consultanță de design și crearea de mobilier personalizat până la coordonarea instalărilor. Ne asigurăm că fiecare piesă completează estetica generală a spațiului, creând un mediu coerent și primitor.",
              description: "Crearea de soluții funcționale și estetice pentru mobilier și decor.",
              des2: "Designurile noastre pun accent pe stil, calitate și funcționalitate.",
              des3: "De la concept la realizare, ne asigurăm că fiecare piesă adaugă valoare spațiului.",
              capabilities: "Oferim o selecție atentă de piese de mobilier și decor de înaltă calitate, alături de servicii de design personalizate pentru a se potrivi viziunii fiecărui client. Echipa noastră se asigură că fiecare piesă sporește estetica și practicitatea spațiului.",
              "ListOfCapabilities": [
                    "Consultanță în design și planificare",
                    "Design personalizat de mobilier",
                    "Selecția materialelor și pieselor de calitate",
                    "Coordonare și instalare",
                    "Stilizare finală și finisaje"
                ],
              approach: "Abordarea noastră pentru mobilier și decor este holistică și orientată către client. Începem cu o consultație detaliată pentru a înțelege viziunea clientului, urmată de achiziții, design personalizat și instalare minuțioasă.",
              "progress": [
                    "Consultanță de design",
                    "Design și selecție personalizată",
                    "Coordonarea instalării",
                    "Stilizare finală"
                ],
            },
            "Exterior-Design": {
              title: "Design exterior",
              subtitle1: "Serviciile noastre de design exterior transformă spațiile exterioare în medii frumoase și funcționale. Folosim o abordare strategică care combină amenajarea peisagistică, mobilierul exterior și selecția mobilierului pentru a spori atât estetica, cât și utilizabilitatea.",
              subtitle2: "De la evaluarea inițială a sitului până la execuția finală, lucrăm îndeaproape cu clienții pentru a crea zone exterioare care să se alinieze cu viziunea lor. Designurile noastre prioritizează sustenabilitatea, practicitatea și atractivitatea vizuală, asigurând un spațiu exterior care se remarcă.",
              description: "Transformarea zonelor exterioare în spații funcționale și frumoase.",
              des2: "Incorporăm viziunea clientului și potențialul sitului pentru un design exterior de impact.",
              des3: "Echipa noastră se ocupă de tot, de la amenajarea peisagistică la iluminat, pentru un rezultat coerent.",
              capabilities: "Capabilitățile noastre de design exterior includ planuri complete de amenajare peisagistică, structuri și iluminat exterior care sporesc estetica și funcționalitatea spațiului.",
              "ListOfCapabilities": [
                    "Evaluarea amplasamentului și analiza fezabilității",
                    "Amenajare peisagistică și construcții exterioare",
                    "Selecția mobilierului de exterior",
                    "Design și implementare a iluminatului",
                    "Coordonare și instalare"
                ],
              approach: "Începem cu o evaluare amănunțită a sitului pentru a înțelege viziunea și constrângerile sitului. Echipa noastră creează apoi planuri detaliate care includ amenajarea peisagistică, structurile exterioare și iluminatul pentru a îmbunătăți atât estetica, cât și funcționalitatea.",
              "progress": [
                    "Evaluarea sitului",
                    "Design de amenajare peisagistică și structuri",
                    "Selecția mobilierului și iluminatului",
                    "Implementare și instalare",
                    "Retușuri finale"
                ],
            },
            "Interior-Design": {
              title: "Design interior",
              subtitle1: "Serviciile noastre de design interior se concentrează pe crearea de spații care sunt atât frumoase, cât și funcționale. Lucrăm îndeaproape cu clienții pentru a înțelege stilul, preferințele și nevoile lor practice, transformând viziunea lor în realitate.",
              subtitle2: "Procesul nostru include planificarea spațiului, selectarea materialelor și finisajelor, amplasarea mobilierului și decorului și gestionarea proiectului. Ne asigurăm că fiecare design se aliniază cu așteptările clientului și îmbunătățește experiența lor zilnică.",
              description: "Crearea de spații interioare care inspiră și îmbunătățesc viața de zi cu zi.",
              des2: "Combinăm estetica și funcționalitatea pentru a crea interioare excepționale.",
              des3: "De la planificare la stilizare, gestionăm fiecare detaliu pentru un finisaj impecabil.",
              capabilities: "Capabilitățile noastre de design interior variază de la planificarea spațiului și selectarea materialelor până la coordonarea completă a proiectului. Ne concentrăm pe crearea unor interioare armonioase, elegante și funcționale care reflectă viziunea clientului.",
              "ListOfCapabilities": [
                    "Consultanță cu clientul și analiza spațiului",
                    "Planificarea spațiului și optimizarea aranjamentului",
                    "Selecția materialelor și finisajelor",
                    "Aranjarea mobilierului și decorului",
                    "Managementul și coordonarea proiectului"
                ],
              approach: "Începem cu o consultație detaliată pentru a capta viziunea și cerințele clientului. Echipa noastră dezvoltă apoi un plan personalizat care îmbină estetica, funcționalitatea și confortul, asigurând un rezultat armonios și elegant.",
              "progress": [
                    "Consultare inițială",
                    "Planificarea spațiului și layout",
                    "Selecția materialelor și mobilierului",
                    "Instalare și stilizare",
                    "Finalizarea proiectului"
                ],
            },
            "Concept-Planning": {
              title: "Planificare concept",
              subtitle1: "Planificarea conceptului este esențială pentru a stabili fundamentul oricărui proiect de succes. Echipa noastră colaborează cu clienții pentru a genera idei, a dezvolta și a rafina ideile inițiale, asigurându-se că acestea se aliniază cu obiectivele proiectului și tendințele pieței.",
              subtitle2: "Odată ce un concept este dezvoltat, avansăm cu planificarea strategică și prototiparea. Procesul nostru iterativ integrează feedback-ul și testarea, rezultând un concept bine definit, gata de implementare.",
              description: "Transformarea ideilor inovatoare în planuri acționabile prin planificare strategică.",
              des2: "Procesul nostru asigură concepte atât inovatoare, cât și fezabile.",
              des3: "De la brainstorming la finalizare, gestionăm fiecare etapă pentru rezultate de succes.",
              capabilities: "Suntem experți în transformarea ideilor inițiale în planuri complete prin brainstorming strategic, analiza pieței și studii de fezabilitate. Obiectivul nostru este să creăm concepte cu impact și pregătite pentru dezvoltare.",
              "ListOfCapabilities": [
                    "Generarea ideilor și brainstorming",
                    "Cercetare de piață și analiză",
                    "Studii de fezabilitate",
                    "Dezvoltarea conceptului și prototipare",
                    "Feedback și rafinare iterativă"
                ],
              approach: "Abordăm planificarea conceptului cu accent pe colaborare și gândire strategică. De la brainstorming-ul inițial la testarea finală, implicăm clienții în fiecare etapă pentru a crea concepte bine rotunjite și acționabile.",
              "progress": [
                "Brainstorming inițial",
                "Analiza pieței și fezabilitate",
                "Dezvoltarea conceptului",
                "Feedback și rafinament",
                "Crearea planului final"
              ],
            }
        },
        "capabilities_title": "Capabilitățile noastre",
        "approach_title": "Abordarea noastră",
        "work_process_title": "Procesul nostru de lucru",
        "related_service_title": "Servicii relevante",
        "contact_title": "Aveți întrebări? Hai să discutăm",
        "contact_subtitle": "Contactează-ne pentru a vedea cum te putem ajuta cu proiectul tău",
        "about": {
        "years_experience": "Ani de Experiență",
        "best_architect": "CEL MAI BUN ARHITECT",
        "small_team": "O echipă mică și eficientă",
        "interior_team": "interior",
        "designing_team": "de design",
        "welcome_message": "bine ați venit la expoziția Hendon Residence"
        },
        "funFact": {
        "shops_made": {
          "count": "450",
          "subTitle": "Magazine",
          "sts": "Realizate"
        },
        "happy_clients": {
          "count": "50",
          "subTitle": "Clienți",
          "sts": "Fericiți"
        },
        "completed_projects": {
          "count": "147",
          "subTitle": "Proiecte",
          "sts": "Finalizate"
        },
        "team_members": {
          "count": "30",
          "subTitle": "Membri",
          "sts": "Echipă"
        }
        }, 
        "faq": {
        "sectionTitle": {
          "subTitle": "Cea mai bună arhitectură",
          "title": "Arhitectura așteptată",
          "highlight": "Strategie inspirată."
        },
        "questions": [
          {
            "id": "1",
            "header": "Arhitectură de bază",
            "body": "Serviciile noastre de arhitectură de bază se concentrează pe oferirea de soluții de design inovative și eficiente care integrează atât forma, cât și funcția. Ne asigurăm că fiecare proiect respectă cele mai înalte standarde de calitate și reflectă viziunea și nevoile unice ale clientului."
          },
          {
            "id": "2",
            "header": "Birouri",
            "body": "Soluțiile noastre de design pentru birouri creează spații care îmbunătățesc productivitatea, confortul și colaborarea. Fiecare design este adaptat pentru a încorpora facilități moderne, lumină naturală și amenajări ergonomice, rezultând în medii care inspiră echipele și sprijină obiectivele de afaceri."
          },
          {
            "id": "3",
            "header": "Magazine",
            "body": "Proiectarea magazinelor, de la supermarketuri mari precum Lidl la magazine de bricolaj precum Leroy Merlin, necesită o abordare strategică a planificării spațiului și a experienței clienților. Designurile noastre se concentrează pe navigare clară, accesibilitatea produselor și atractivitate vizuală, asigurând un mediu de cumpărături eficient și atractiv."
          },
          {
            "id": "4",
            "header": "Restaurante",
            "body": "Serviciile noastre de design pentru restaurante îmbină atmosfera, funcționalitatea și experiența clientului. Creăm spații primitoare care optimizează aranjamentul scaunelor, asigură un flux de trafic fluid și încorporează identitatea de brand, rezultând într-o experiență de dining memorabilă."
          }
        ]
        },
        "contactForm": {
            "title": "Solicită o Vizită",
            "fields": {
            "name": "Nume",
            "email": "Email",
            "subject": "Alege un serviciu",
            "message": "Mesaj"
            },
            "serviceOptions": {
            "designPlanning": "PROIECTARE & PLANIFICARE",
            "customSolutions": "SOLUȚII PERSONALIZATE",
            "furnitureDecor": "MOBILIER & DECOR"
            },
            "validation": {
            "name": "required|alpha_space",
            "email": "required|email",
            "message": "required",
            "namemessage": "Câmpul pentru nume este obligatoriu.",
            "emailmessage": "Câmpul pentru email este obligatoriu și trebuie să fie o adresă de email validă.",
            "messagemessage": "Câmpul pentru mesaj este obligatoriu."
            },
            "submit": "Trimite",
            "success": "Mesaj trimis cu succes!",
            "error": "A apărut o eroare, vă rugăm să încercați din nou."
        },
        "ctaSection": {
        "title": "în Acțiune",
        "subtitle": "Transformă Ideile",
        "fields": {
            "email": "Introdu adresa de email"
        },
        "submit": "Abonează-te"
        },
        "projectSection": {
            "chooseProject": "Alege un",
            "project": "Proiect",
            "description": "Vă putem ajuta să vă îmbunătățiți afacerea",
            "altText": "Apartament",
            "iconAltText": "Pictogramă",
            "size": "de la {{size}} m2",
            "descriptions": {
                "Casa Vlăsia": "Mobilier premium",
                "HA RO": "Magazin complet",
                "Leroy Merlin": "Lanț de magazine",
                "La Doi Pași": "Până la 25 anual",
                "Any Shop": "Peste 450 de magazine deja realizate",
                "Apartaments and Houses": "Peste 20 de case și apartamente"
            }
        },
        "projectSingle": {
            "pageSub": "Proiect",
            "modernDevelopment": "O Dezvoltare Modernă",
            "keyFacilities": "Facilități Cheie:",
            "projectPark": "Parc Proiect",
            "gallery": "Galerie",
            "retailShops": "Magazine",
            "facilities": {
            "wellnessSpa": "Wellness & Spa: Un spațiu liniștit dedicat relaxării și reîmprospătării.",
            "conferenceRooms": "Săli de conferințe: Echipate cu facilități AV moderne pentru evenimente corporative și întâlniri.",
            "restaurant": "Restaurant: Opțiuni de cină rafinate cu o gamă de bucătării internaționale.",
            "fitnessCenter": "Centru de fitness: Sală de sport de lux cu antrenori personali și echipamente moderne.",
            "library": "Bibliotecă: Spațiu liniștit cu o vastă colecție de cărți și resurse digitale.",
            "rooftopBars": "Baruri pe acoperiș: Spații de relaxare oferind vederi panoramice și cocktailuri."
            }
        },
        "plans": {
            "projectPlans": "Proiect",
            "plans": "Planuri",
            "buildingPlan": "Planul Clădirii",
            "render": "Redare",
            "furnitureProject": "Proiectul Mobilierului",
            "implementation": "Implementare",
            "imageNotAvailable": "Imagine indisponibilă"
        },
      },
    },
  },
  lng: localStorage.getItem('i18nextLng') || 'en', // Setăm limba implicită din localStorage
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  detection: {
    caches: ['localStorage'], // Salvăm preferința limbii în localStorage
  },
});

export default i18n;
