import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import ProjectSectionS2 from '../../components/ProjectSectionS2/ProjectSectionS2';
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';


const ProjectPage =() => {
    
    const { t } = useTranslation();
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={t('Projects')} pagesub={t('Projects')}/> 
            <ProjectSectionS2/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ProjectPage;
