import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = () => {
    const [menuActive, setMenuState] = useState(false);

    const toggleMenu = () => {
        setMenuState(!menuActive);
    };

    return (
        <div>
            {/* Mobile Menu */}
            <div
                className={`mobileMenu ${menuActive ? 'show' : ''}`}
                style={{
                    position: 'fixed',
                    right: menuActive ? '0' : '-300px', // Slide in/out from the right
                    top: 0,
                    zIndex: 9999,
                    height: '100vh',
                    width: '300px',
                    background: '#1e1e1e',
                    transition: 'right 0.3s ease', // Adjust transition property to 'right'
                    boxShadow: '0px 10px 40px rgba(50, 50, 50, 0.1)',
                    overflowY: 'auto',
                    display: menuActive ? 'block' : 'none' // Only show when active
                }}
            >
                <div className="menu-close">
                    <div
                        className="close"
                        onClick={toggleMenu}
                        style={{
                            width: '40px',
                            height: '40px',
                            lineHeight: '40px',
                            textAlign: 'center',
                            background: '#ca9c6a',
                            position: 'absolute',
                            top: '15px',
                            left: '15px', // Adjusted to the left side for consistency
                            color: '#fff',
                            cursor: 'pointer',
                            fontSize: '24px',
                            borderRadius: '50%',
                        }}
                    >
                        ✕
                    </div>
                </div>

                <ul className="responsivemenu" style={{ listStyle: 'none', paddingLeft: '0', paddingTop: '40px' }}>
                    <li>
                        <Link to="/" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                            About
                        </Link>
                    </li>
                    <li>
                        <Link to="/property" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link to="/services" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" style={{ color: '#fff', display: 'block', padding: '13px 20px' }}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Burger Menu Icon in Bottom-Right Corner, Visible Only on Mobile and Tablet */}
            <div
                className="showmenu"
                onClick={toggleMenu}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    right: '20px',
                    bottom: '20px',
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#1e1e1e',
                    cursor: 'pointer',
                    zIndex: 10000,
                }}
            >
                <i style={{ fontSize: '24px', color: '#fff', lineHeight: '0',
                            marginRight: '2px',
                            marginBottom: '5px' }}>☰</i>
            </div>

            <style jsx>{`
                /* Hide the burger icon and mobile menu on larger screens */
                @media (min-width: 991px) {
                    .mobileMenu {
                        display: none !important;
                    }
                    .showmenu {
                        display: none !important;
                    }
                }
            `}</style>
        </div>
    );
};

export default MobileMenu;
