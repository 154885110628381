import React from 'react';
import abimg from '../../images/wp/wp-3.png';
import { useTranslation } from 'react-i18next';

const About2 = () => {
    const { t } = useTranslation();

    return (
        <section className="about-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="about-image mr-50 rmr-0">
                            <div className="experience-year">
                                <span className="number">25</span>
                                <h4>{t('about.years_experience')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="wp-content about-content mb-10 ml-50 pt-100 rmb-50">
                            <div className="section-title mb-10">
                                <h6>{t('about.best_architect')}</h6>
                                <h2>
                                    {t('about.small_team')} <br />
                                    <span>{t('about.interior_team')}</span> <br />
                                    <span className="thin">{t('about.designing_team')}</span>
                                </h2>
                                <h6>{t('about.welcome_message')}</h6>
                            </div>
                            <img src={abimg} alt="About" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About2;
