import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar2/Navbar2'
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Services from '../../api/Services';
import Contact from './contact';
import Footer from '../../components/footer/Footer';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ServiceSinglePage = (props) => {

    const { slug } = useParams();
    
    const { t } = useTranslation();

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const serviceDetails = Services.find(item => item.slug === slug);

    const capabilities = t(`services.${slug}.ListOfCapabilities`, { returnObjects: true });
    const workProgress = t(`services.${slug}.progress`, { returnObjects: true });

    return (
        <Fragment>
            <Navbar hclass={'header-style-2'} />
            <PageTitle pageTitle={t(`services.${slug}.title`)} pagesub={t('Services')} />
            <div className="service-single-area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="service-single-wrap">
                                <div className="service-single-item">
                                    <div className="service-single-main-img">
                                        <img src={serviceDetails.sSimg} alt="" />
                                    </div>
                                    <div className="service-single-title">
                                        <h3>{t(`services.${slug}.title`)}</h3>
                                    </div>
                                    <p>{t(`services.${slug}.subtitle1`)}</p>
                                    <p>{t(`services.${slug}.subtitle2`)}</p>
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="p-details-img">
                                                <img src={serviceDetails.ssImg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="p-details-img">
                                                <img src={serviceDetails.ssImg2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-single-item list-widget">
                                    <div className="service-single-title">
                                        <h3>{t('capabilities_title')}</h3>
                                    </div>
                                    <p>{t(`services.${slug}.capabilities`)}</p>
                                    <div>
                                    <ul>
                                    {capabilities.map((capability, index) => (
                                        <li key={index}>{capability}</li>
                                    ))}
                                    </ul>
                                    </div>
                                </div>
                                <div className="service-single-item">
                                    <div className="service-single-title">
                                        <h3>{t('approach_title')}</h3>
                                    </div>
                                    <p>{t(`services.${slug}.approach`)}</p>
                                </div>
                                <div className="service-single-item list-widget">
                                    <div className="service-single-title">
                                        <h3>{t('work_process_title')}</h3>
                                    </div>
                                    <ul>
                                    {workProgress.map((progress, index) => (
                                        <li key={index}>{progress}</li>
                                    ))}
                                    </ul>
                                </div>
                                <div className="service-single-item">
                                    <div className="service-single-title">
                                        <h3>{t('related_service_title')}</h3>
                                    </div>
                                    <div className="service-area">
                                        <div className="row align-items-center">
                                            {Services.slice(0,3).map((service, srv) => (
                                                <div className="col-lg-4 col-md-6 col-12" key={srv}>
                                                    <div className="service-item">
                                                        <i><img src={service.sImg} alt="" /></i>
                                                        <h3><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{t(`services.${service.slug}.title`)}</Link></h3>
                                                        <p>{t(`services.${slug}.description`)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="service-single-item">
                                    <div className="service-contact-area">
                                        <div className="contact-title">
                                            <h2>{t('contact_title')}</h2>
                                            <p>{t('contact_subtitle')}</p>
                                        </div>
                                        <div className="contact-form-area">
                                            <Contact/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;
