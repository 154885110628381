import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const Plans = ({ planImages = [] }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const planData = [
        { id: '1', title: t('plans.buildingPlan') },
        { id: '2', title: t('plans.render') },
        { id: '3', title: t('plans.furnitureProject') },
        { id: '4', title: t('plans.implementation') }
    ];

    return (
        <section className="apartment-plan text-center mt-135">
            <div className="container">
                <div className="section-title mb-50">
                    <h2>{t('plans.projectPlans')} <span className="thin">{t('plans.plans')}</span></h2>
                </div>
                <div className="apartment-tab">
                    <Nav tabs className="justify-content-center mb-65">
                        {planData.map((plan) => (
                            <NavItem key={plan.id}>
                                <NavLink
                                    className={classnames({ active: activeTab === plan.id })}
                                    onClick={() => toggle(plan.id)}
                                >
                                    {plan.title}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {planData.map((plan, index) => (
                            <TabPane tabId={plan.id} key={plan.id}>
                                <h3>{plan.title}</h3>
                                {planImages[index] ? (
                                    <img src={planImages[index]} alt={`${plan.title}`} />
                                ) : (
                                    <p>{t('plans.imageNotAvailable')}</p>
                                )}
                            </TabPane>
                        ))}
                    </TabContent>
                </div>
            </div>
        </section>
    );
};

export default Plans;
