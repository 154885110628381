import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: t('contactForm.validation.namemessage'),
            email: t('contactForm.validation.emailmessage'),
            subject: t('contactForm.validation.subjectmessage'),
            message: t('contactForm.validation.messagemessage')
        }
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={submitHandler} className="contact-validation-active">
            <div className="row">
                <div className="col col-lg-6 col-md-6 col-12">
                    <div className="form-field">
                        <input
                            className="form-control"
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder={t('contactForm.fields.name')}
                        />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-md-6 col-12">
                    <div className="form-field">
                        <input
                            className="form-control"
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder={t('contactForm.fields.email')}
                        />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            value={forms.subject}
                            name="subject"
                        >
                            <option value="">{t('contactForm.fields.subject')}</option>
                            <option value="designPlanning">{t('contactForm.serviceOptions.designPlanning')}</option>
                            <option value="customSolutions">{t('contactForm.serviceOptions.customSolutions')}</option>
                            <option value="furnitureDecor">{t('contactForm.serviceOptions.furnitureDecor')}</option>
                        </select>
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>
                <div className="col fullwidth col-lg-12">
                    <textarea
                        className="form-control"
                        onBlur={changeHandler}
                        onChange={changeHandler}
                        value={forms.message}
                        name="message"
                        placeholder={t('contactForm.fields.message')}
                    />
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">
                    {t('contactForm.submitButton')}
                </button>
            </div>
        </form>
    );
};

export default Contact;
