import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const FunFact = (props) => {
    const { t } = useTranslation();

    const funFacts = [
        {
            title: t('funFact.shops_made.count'),
            subTitle: t('funFact.shops_made.subTitle'),
            sts: t('funFact.shops_made.sts'),
        },
        {
            title: t('funFact.happy_clients.count'),
            subTitle: t('funFact.happy_clients.subTitle'),
            sts: t('funFact.happy_clients.sts'),
        },
        {
            title: t('funFact.completed_projects.count'),
            subTitle: t('funFact.completed_projects.subTitle'),
            sts: t('funFact.completed_projects.sts'),
        },
        {
            title: t('funFact.team_members.count'),
            subTitle: t('funFact.team_members.subTitle'),
            sts: t('funFact.team_members.sts'),
        },
    ];

    return (
        <section className={`success-section mb-115 ${props.fClass}`}>
            <div className="container">
                <div className="row">
                    {funFacts.map((funFact, index) => (
                        <div className="col-xl-3 col-md-6" key={index}>
                            <div className="success-box">
                                <span className="count-text">
                                    <CountUp end={parseInt(funFact.title)} enableScrollSpy />
                                </span>
                                <div className="success-content">
                                    <h3>{funFact.subTitle}</h3>
                                    <h6>{funFact.sts}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <span id="counter" className="d-none" />
        </section>
    );
};

export default FunFact;
