import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useParams } from 'react-router-dom';
import Project from '../../api/Projects';
import Plans from './Plans';
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';

const ProjectSinglePage = () => {
    const { slug } = useParams();
    const { t } = useTranslation();

    // Find the project by slug
    const projectDetails = Project.find(item => item.slug === slug);

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={projectDetails.pTitle} pagesub={t('projectSingle.pageSub')} />
            <div className="project-single-area section-padding">
                <div className="property-single mt-150">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="property-single-left">
                                    <div className="property-left-content">
                                        <h3><span className="font-weight-light">{t('projectSingle.modernDevelopment')}</span></h3>
                                        <ul>
                                            <li>{t('projectSingle.facilities.wellnessSpa')}</li>
                                            <li>{t('projectSingle.facilities.conferenceRooms')}</li>
                                            <li>{t('projectSingle.facilities.restaurant')}</li>
                                            <li>{t('projectSingle.facilities.fitnessCenter')}</li>
                                            <li>{t('projectSingle.facilities.library')}</li>
                                            <li>{t('projectSingle.facilities.rooftopBars')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="property-single-right">
                                    <div className="property-right-content pl-40 rpl-0">
                                        <h3 className="border-text">{t('projectSingle.keyFacilities')}</h3>
                                        <h3>{t('projectSingle.projectPark')}</h3>
                                        <h3>{t('projectSingle.gallery')}</h3>
                                        <h3>{t('projectSingle.retailShops')}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Plans planImages={projectDetails.planImages} />
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default ProjectSinglePage;
