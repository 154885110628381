import pImgA1 from '../images/apartment/doctor.png';
import pImg2 from '../images/apartment/apartment2.png';
import pImg3 from '../images/apartment/apartment3.png';

import pSImg1 from '../images/apartment/apartment1.png';
import pSImg2 from '../images/apartment/apartment2.png';
import pSImg3 from '../images/apartment/apartment3.png';

import sIcon1 from '../images/apartment/icon1.png';
import sIcon2 from '../images/apartment/icon2.png';
import sIcon3 from '../images/apartment/icon3.png';

import pImgCV1 from '../images/casa-vlasia/CasaVlasiaBar.jpeg';

import pImgHR1 from '../images/haro/haro.png';

import pImgLM1 from '../images/leroy-merlin/leroy-culori.png';

import pImgLDP1 from '../images/ladoipasi/insula.png';

import pImgOS1 from '../images/othershop/vuse.png';

import haroPlan from '../images/haro/haroPlan.png';
import casaVlasiaPlan from '../images/casa-vlasia/casaVlasiaPlan.png';
import leroyMerlinPlan from '../images/leroy-merlin/leroyMerlinPlan.png';
import laDoiPasiPlan from '../images/ladoipasi/laDoiPasiPlan.png';
import anyShopPlan from '../images/othershop/anyshopPlan.png';
import apartmentPlan from '../images/apartment/apartamentsPlan.png';

import apartmentFinal from '../images/apartment/doctor.png';
import casaVlasiaFinal from '../images/casa-vlasia/CasaVlasiaBar.jpeg';
import laDoiPasiFinal from '../images/ladoipasi/laDoiPasiFinal.jpeg';
import leroyMerlinFinal from '../images/leroy-merlin/leroyMerlinFinal.jpeg';
import avonFinal from '../images/othershop/avonFinal.jpeg';
import apartamentFinal from '../images/apartment/apartamentFinal.jpeg';

import haroPolly from '../images/haro/haroPolly.png';
import leroyMerlinPolly from '../images/leroy-merlin/leroyMerlinPolly.png';
import laDoiPasiPolly from '../images/ladoipasi/laDoiPasiPolly.png';
import avonPolly from '../images/othershop/avonPolly.png';
import casaVlasiaPolly from '../images/casa-vlasia/CasaVlasiaPolly.jpeg';
import apartamentPolly from '../images/apartment/apartamentPolly.jpeg';

import casaVlasiaSkp from '../images/casa-vlasia/CasaVlasiaSkp.png';
import haroSkp from '../images/haro/haroskp.png';
import leroySkp from '../images/leroy-merlin/leroyMerlinSkp.jpeg';
import laDoiPasiSkp from '../images/ladoipasi/laDoiPasiSkp.jpeg';
import avonSkp from '../images/othershop/avonSkp.jpeg';
import apartamentSkp from '../images/apartment/apartamentSkp.jpeg';

const Projects = [
    {
        Id: '1',
        pTitle: 'Casa Vlăsia', 
        slug: 'Casa Vlăsia',
        pImg: pImgCV1,
        pSImg: pSImg1,
        ssIcon: sIcon1,
        des: 'Premium Furniture',
        width: '500',
        planImages: [casaVlasiaPlan, casaVlasiaSkp, casaVlasiaPolly, casaVlasiaFinal]  // Dummy images for Casa Vlăsia
    },
    {
        Id: '2',
        pTitle: 'HA RO', 
        slug: 'HA RO',
        pImg: pImgHR1,
        pSImg: pSImg2,
        ssIcon: sIcon2,
        des: 'Full Shop',
        width: '1000',
        planImages: [haroPlan, haroSkp, haroPolly, pImgHR1]  // Dummy images for HA RO
    },
    {
        Id: '3',
        pTitle: 'Leroy Merlin', 
        slug: 'Leroy Merlin',
        pImg: pImgLM1,
        pSImg: pSImg3,
        ssIcon: sIcon3,
        des: 'Chain of shops',
        width: '750',
        planImages: [leroyMerlinPlan, leroySkp, leroyMerlinPolly, leroyMerlinFinal]  // Dummy images for Leroy Merlin
    },
    {
        Id: '4',
        pTitle: 'La Doi Pași', 
        slug: 'La Doi Pași',
        pImg: pImgLDP1,
        pSImg: pSImg1,
        ssIcon: sIcon1,
        des: 'Up to 25 yearly',
        width: '1400',
        planImages: [laDoiPasiPlan, laDoiPasiSkp, laDoiPasiPolly, laDoiPasiFinal]  // Dummy images for La Doi Pași
    },
    {
        Id: '5',
        pTitle: 'Market', 
        slug: 'Any Shop',
        pImg: pImgOS1,
        pSImg: pSImg2,
        ssIcon: sIcon2,
        des: 'Over 450 shops already made',
        width: '5400',
        planImages: [anyShopPlan, avonSkp, avonPolly, avonFinal]  // Dummy images for Any Shop
    },
    {
        Id: '6',
        pTitle: 'Apartaments', 
        slug: 'Apartaments and Houses',
        pImg: pImgA1,
        pSImg: pSImg3,
        ssIcon: sIcon3,
        des: 'Over 20 houses and apartments',
        width: '140',
        planImages: [apartmentPlan, apartamentSkp, apartamentPolly, apartamentFinal]  // Dummy images for Apartments
    }
];

export default Projects;
