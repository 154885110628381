import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png';
import fImg1 from '../../images/services/img1.png';
import fImg2 from '../../images/services/img2.png';

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const FaqSectionS2 = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        setOpen(open === id ? undefined : id);
    };

    return (
        <section className="faq-section mt-150">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div className="service-page-images rmb-150">
                            <img src={fImg1} alt="Services" />
                            <img src={fImg2} alt="Service" />
                        </div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>{t('faq.sectionTitle.subTitle')}</h6>
                                <h2>
                                    {t('faq.sectionTitle.title')}{' '}
                                    <span className="thin">{t('faq.sectionTitle.highlight')}</span>
                                </h2>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    {t('faq.questions', { returnObjects: true }).map((question) => (
                                        <AccordionItem key={question.id} className="card">
                                            <AccordionHeader targetId={question.id} className="card-header">
                                                {question.header}
                                            </AccordionHeader>
                                            <AccordionBody accordionId={question.id}>
                                                {question.body}
                                            </AccordionBody>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSectionS2;
