import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage from '../../components/Contactpage/Contactpage'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';

const ContactPage =() => {
    
    const { t } = useTranslation();
    return(
        <Fragment>
            <Navbar hclass={'header-style-2'}/>
            <PageTitle pageTitle={t('Contact us')} pagesub={t('Contact us')}/> 
            <Contactpage/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

