import React from 'react';
import { useTranslation } from 'react-i18next';

const CtaSection = (props) => {
    const { t } = useTranslation();

    const SubmitHandler = (e) => {
        e.preventDefault();
    };

    return (
        <section className="call-action">
            <div className="container">
                <div className="call-action-inner">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="section-title text-white rmb-35">
                                <h6>{t('ctaSection.subtitle')}</h6>
                                <h2>{t('ctaSection.title')}</h2>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={SubmitHandler} className="subscribe">
                                <input 
                                    type="email" 
                                    placeholder={t('ctaSection.fields.email')} 
                                    required 
                                />
                                <button type="submit" className="theme-btn style-two">
                                    {t('ctaSection.submit')}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CtaSection;
