import React, { useState } from 'react';
import fImg from '../../images/faq/faq-right-bg.png';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const FaqSection = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState('1');

    const toggle = (id) => {
        setOpen(open === id ? null : id);
    };

    const faqQuestions = t('faq.questions', { returnObjects: true });

    return (
        <section className="faq-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 pr-125 rpr-15">
                        <div className="faq-left-image"></div>
                    </div>
                    <div className="col-lg-6 pl-0">
                        <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
                            <div className="section-title mb-35">
                                <h6>{t('faq.sectionTitle.subTitle')}</h6>
                                <h2>{t('faq.sectionTitle.title')}
                                    <span className="thin"> {t('faq.sectionTitle.highlight')}</span>
                                </h2>
                            </div>
                            <div id="accordion">
                                <Accordion open={open} toggle={toggle}>
                                    {faqQuestions.map((question) => (
                                        <AccordionItem className="card" key={question.id}>
                                            <AccordionHeader targetId={question.id} className="card-header">
                                                {question.header}
                                            </AccordionHeader>
                                            <AccordionBody accordionId={question.id}>
                                                {question.body}
                                            </AccordionBody>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                        <div className="faq-right-bg">
                            <img src={fImg} alt="FAQ" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
